@media (prefers-color-scheme: dark) {

    body {
        background-color: black;
        color: var(--synth-broken-white);
    }

    .navbar.fixed-top  {
        background-color: rgba(0, 0, 0, 0.9) !important;
        border-top: 1px solid var(--synth-broken-white) !important;
    }

    .navbar.fixed-bottom {
        background-color: rgba(0, 0, 0, 0.8) !important;
    }
    .card-header .nav-tabs {
         border-bottom: #eef0f3; 
         border-bottom: 1px solid rgba(0,0,0,.125); 
    }
    
    .card {
        background-color: var(--synth-mt-black) !important;
        color: white;
        box-shadow: none;
    }
    /* whitewash the fonts */
    .text-dark, .text-secondary  { 
      color: var(--synth-gray) !important;
    }

    .text-light { 
      color: white !important;
    }

    .navbar-nav .nav-link {
      color: var(--synth-broken-white);
    }
    
    
    .form-control, .custom-file-input, .was-validated,.form-control:focus  {

      background-color: var(--synth-mt-black);
      color:white !important;
      color-scheme: dark;
    }

    .custom-select {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba(255,255,255)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
    }
    

    .form-control.is-invalid {
      color:var(--synth-dark-red);
      border-color: red;
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: var(--synth-mt-black) !important;
        color: var(--synth-gray) !important;
        border-color: black;
 
      }

    nav .navbar-brand {
      height: 42px;
      background-image: none;
      padding-top: 0.23rem;
      font-size: 1.30rem;
      margin-right: 20px !important;

    }

    nav .navbar-brand::before {
      content: "Synthe";
      color:var(--synth-mid-blue);
    }
    nav .navbar-brand::after {
      content: "tron";
      color:var(--synth-green);
      position: relative;
     
      
    }

    nav.border-bottom {
        border-bottom: 2px solid var(--synth-broken-white) !important;
    }


    .nav-link:focus,.nav-link:hover {
        color: var(--synth-grey) !important;
    }
    a.text-dark:focus,a.text-dark:hover {
        color: var(--synth-grey) !important;
    }

 table.table-light {
    color: black !important;
  }

  table.table {

  color: #fdfeff;
}

    .tooltip > .tooltip-inner {
        color: white;
        opacity: 1;
    }


    #searchBox {
        background-color: black;  
        color: var(--synth-gray) !important;
        height: 37px;
    }

    #setCurrentTimeRange, #setCurrentAccountId {
        background-color: black;
        color: var(--synth-broken-white);
    }

    .table-hover tr:hover {
        background-color: black !important;
        color: var(--synth-gray) !important;
    } 

    .thead-light {
        background-color: var(--synth-gray) !important;
    }
    
    table#sortable > thead > tr {
        color: var(--synth-broken-white);
        
    }
    .border-light {
        border-color: var(--synth-gray) !important;
    }
    .img-thumbnail {
      background-color: black;
    }

    #memberSelect, #ownerSelect, #adminSelect {
        background-color: var(--synth-mt-black);
        color:white !important

    }

    .nav-tabs .nav-link.active {
        background-color:transparent !important;
        color:white;
    }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link, .navbar {

        color:var(--synth-gray);
    }
    
    .nav-tabs .nav-item {

        color:var(--synth-broken-white);
    }
    
    .navbar-nav .nav-link {
        color: white;
    }


    .page-item .page-link {
        background-color: var(--synth-mt-black) !important;
        color: white;
    }

    .page-item.active .page-link {

        background-color: var(--synth-mid-blue) !important;
        border-color: var(--synth-mid-blue);
    }


    .nav-item.active > .nav-link {
        color: var(--synth-green);
    }

    .modal-content {
        background-color: var(--synth-mt-black);

    }

    .alert {
      background-color: black !important;
      color: inherit;
    }
    
    .alert-success {

        color: var(--synth-green) !important; 
    }
    
    .page-list  {
        box-shadow: none !important;
    }

    .navbar-toggler {
        color: white !important;
        border-color: white !important;
    }
    
    .btn {
        color:white;
    }
    
    .input-group-text {
        background-color: var(--synth-mt-black) !important;
        color:var(--synth-gray);
    }
    
    .dropdown-menu {
        background-color: black;
        color:white;
        opacity: 0.8;
    }

    .dropdown-item {
        color:var(--synth-broken-white);
    }
    
    .dark-mode-enabled {    
        display: block;
    } 
    
    .dark-mode-enabled::before {
      content: "enabled";
      color:var(--synth-green);
    }
    
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      }
      
      
      th[sort-col]:hover {
            background-color: var(--synth-gray);
            color: black;
        }
        
      .nav-tabs {
        border-bottom: 1px solid var(--synth-broken-white);
      }
      
    .btn-outline-primary {
      border-color: var(--synth-broken-white) !important;
      color: var(--synth-broken-white) !important;
    }
    
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
        border-color:white white var(--synth-mt-black);
    }

/*  .nav-tabs .nav-link.active {

    border-bottom: solid var(--synth-mt-black) !important;
    }*/
    
    .card-header .nav-tabs .nav-link.active {
        border-bottom: solid transparent !important;
        color: white !important;
      }

  a {
    color: var(--synth-broken-white);
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        color: var(--synth-green) !important;
    }



    .table-primary, .table-primary>td, .table-primary>th {
        background-color: black;
    }
    
table.border-primary tbody {
    background-color: inherit;
}

}
