:root {
    
  /* Logo Colors */
  --synth-light-blue: #72c7e7; /* R: 114 G: 199 B: 231 */ 
  --synth-blue: #004250;  /* R: 0 G: 66 B: 80 */ 
  --synth-red: #D52B1E; /* R: 213 G: 43 B: 30 */
  --synth-mid-blue: #0075b0; /* R: 0 G: 117 B: 176 PRIMARY */  
  --synth-green: #7AB800; /* R: 122 G: 184 B: 0  */
  --synth-light-brown: #B3B38C; /* R: 179 G: 179 B: 140 */
  --synth-light-gray: #e0e6e6; /* R: 224 G: 230 B: 230 */
  --synth-dark-red: #A71930; /* R: 167 G: 25 B: 30 */
  --synth-dark-green: #006751; /* R: 0 G: 103 B: 81 */
  --synth-yellow: #FECB00; /* R: 254 G: 203 B: 0  */
  --synth-orange: #F57000; /* R: 245 G: 112 B: 0 */
  --synth-brown: #4F4C25;
  --synth-dark-blue: #003F72; /*moderator blue */ 
  --synth-darker-green: #1d7b32;
  --synth-gray: #bfbfbf; /* R: 191 G: 191 B: 191 */
  --synth-mt-black:#343a40;
  --synth-mt-black-dark:#212529;
  --synth-broken-white:floralwhite;
  
}


$enable-responsive-font-sizes: true;
$theme-light-blue: #72c7e7; /* R: 114 G: 199 B: 231 */ 
$theme-blue: #004250;  /* R: 0 G: 66 B: 80 */ 
$theme-light-green: #B6B;  /* R: 191 G: 228 B: 98 */ 
$theme-red: #D52B1E; /* R: 213 G: 43 B: 30 */
$theme-mid-blue: #0075b0; /* R: 0 G: 117 B: 176 PRIMARY */  
$theme-green: #7AB800; /* R: 122 G: 184 B: 0  */
$theme-light-brown: #B3B38C; /* R: 179 G: 179 B: 140 */
$theme-light-gray: #e0e6e6; /* R: 224 G: 230 B: 230 */
$theme-dark-red: #A71930; /* R: 167 G: 25 B: 30 */
$theme-dark-green: #006751; /* R: 0 G: 103 B: 81 */
$theme-yellow: #FECB00; /* R: 254 G: 203 B: 0  */
$theme-orange: #F57000; /* R: 245 G: 112 B: 0 */
$theme-brown: #4F4C25;
$theme-dark-blue: #003F72; /*moderator blue */ 
$theme-darker-green: #1d7b32;
$theme-gray: #bfbfbf; /* R: 191 G: 191 B: 191 */
$transparent: rgba(255, 255, 255, .60);
 
/* Associate Synthetron colors to standard Bootstrap theme colors */
$theme-colors: (
  "primary": $theme-mid-blue,
  "danger": $theme-red,
  "warning": $theme-orange,
  "success":$theme-green,
  "info": $theme-light-blue,
  "light":  $theme-light-gray,
  "dark": $theme-dark-blue
);

// .card {
//   width: 640px;
// }


// .material-icons {
//   vertical-align: middle;

// }

.login-image {
  width: 64%;
}
.text-black {
  color:black;
}

amplify-authenticator  .amplify-tabs,amplify-authenticator  .amplify-label {
  display: none;
}
// .amplify-field-group__outer-end

amplify-authenticator > div {
  padding-top: 15px;
}
@media (prefers-color-scheme: dark) {

[data-amplify-authenticator] [data-amplify-router] {

  background-color: var(--synth-mt-black-dark);
  box-shadow: none;
  border-width: 0;
  border-radius: .5rem;

  
  --amplify-components-button-primary-background-color: var(
    --synth-mid-blue
  );
  --amplify-components-button-primary-hover-background-color: black;
  --amplify-components-button-primary-focus-background-color: var(
    --synth-darker-green
  );
  --amplify-components-button-primary-active-background-color: var(
    --synth-darker-green
  );
  --amplify-components-button-link-color: var(
    --synth-gray
  );
  --amplify-components-button-link-hover-color: black;
  --amplify-components-button-link-focus-color: var(
    --amplify-colors-brand-secondary-90
  );
  --amplify-components-button-link-active-color: var(
    --amplify-colors-brand-secondary-100
  );
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
}
.amplify-input {
  color: inherit;
  font-size: var(--amplify-components-fieldcontrol-font-size);
  line-height: var(--amplify-components-fieldcontrol-line-height);
  padding-block-start: var(--amplify-components-fieldcontrol-padding-block-start);
  padding-block-end: var(--amplify-components-fieldcontrol-padding-block-end);
  padding-inline-start: var(--amplify-components-fieldcontrol-padding-inline-start);
  padding-inline-end: var(--amplify-components-fieldcontrol-padding-inline-end);

  --amplify-components-fieldcontrol-color:transparent;
  --amplify-components-fieldcontrol-border-color: var( --amplify-components-input-border-color );
  --amplify-components-fieldcontrol-font-size: var( --amplify-components-input-font-size );
  --amplify-components-fieldcontrol-focus-border-color: white;
  --amplify-components-fieldcontrol-focus-font-color: white;

}

amplify-button--primary:focus {
  --amplify-internal-button-background-color: var( --amplify-components-button-primary-focus-background-color );
  --amplify-internal-button-border-color: var( --amplify-components-button-primary-focus-border-color );
  --amplify-internal-button-color: var( --synth-darker-green );
  --amplify-internal-button-focus-box-shadow: var( --amplify-components-button-primary-focus-box-shadow );
}



}