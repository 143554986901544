

/* validated input Joanne */

/* Associate Synthetron colors to standard Bootstrap theme colors */

/* Primary overrides*/
.text-primary {
    color: var(--synth-mid-blue) !important; 
}
.bg-primary, .badge-primary {
    background-color: var(--synth-mid-blue) !important;
}
.border-primary {
    border-color: var(--synth-mid-blue) !important;     
}
.btn-primary {    
    background-color: var(--synth-mid-blue) !important; 
    border-color: var(--synth-mid-blue) !important; 
}
.btn-outline-primary {      
    border-color: var(--synth-mid-blue) !important; 
    color: var(--synth-mid-blue) !important; 
}
.btn-outline-primary:hover {
    background-color: var(--synth-mid-blue) !important; 
    color: var(--synth-light-gray) !important;
}
.btn-outline-primary:a {
    color: var(--synth-mid-blue) !important;
}


/* Danger overrides*/
.text-danger {
    color: var(--synth-red) !important; 
}
.bg-danger, .badge-danger {
    background-color: var(--synth-red) !important;
}
.border-danger {
    border-color: var(--synth-red) !important;     
}
.btn-danger {    
    background-color: var(--synth-red) !important; 
    border-color: var(--synth-red) !important; 
}
.btn-outline-danger {      
    border-color: var(--synth-red) !important; 
    color: var(--synth-red) !important; 
}
.btn-outline-danger:hover {
    background-color: var(--synth-red) !important; 
    color: white !important;
}
.btn-outline-danger:a {
    color: var(--synth-red) !important;
}


/* Warning overrides*/
.text-warning {
    color: var(--synth-orange) !important; 
}
.bg-warning, .badge-warning {
    background-color: var(--synth-orange) !important;
}
.border-warning {
    border-color: var(--synth-orange) !important;     
}
.btn-warning {    
    background-color: var(--synth-orange) !important; 
    border-color: var(--synth-orange) !important; 
    color: white !important;
}
.btn-outline-warning {      
    border-color: var(--synth-orange) !important; 
    color: var(--synth-orange) !important; 
    
}
.btn-outline-warning:hover {
    background-color: var(--synth-orange) !important; 
    color: white !important;
}
.btn-outline-warning:a {
    color: var(--synth-orange) !important;
}

/* Success overrides*/
.text-success {
    color: var(--synth-green) !important; 
}
.bg-success, .badge-success {
    background-color: var(--synth-green) !important;
}
.border-success {
    border-color: var(--synth-green) !important;     
}
.btn-success {    
    background-color: var(--synth-green) !important; 
    border-color: var(--synth-green) !important; 
}
.btn-outline-success {      
    border-color: var(--synth-green) !important; 
    color: var(--synth-green) !important; 
}
.btn-outline-success:hover {
    background-color: var(--synth-green) !important; 
    color: white !important;
}
.btn-outline-success:a {
    color: var(--synth-green) !important;
}

/* Info overrides*/
.text-info {
    color: var(--synth-light-blue) !important; 
}
/*.text-info:a {
    color: white !important;
}*/

.bg-info, .badge-info {
    background-color: var(--synth-light-blue) !important;
}
.border-info {
    border-color: var(--synth-light-blue) !important;     
}
.btn-info {    
    background-color: var(--synth-light-blue) !important; 
    border-color: var(--synth-light-blue) !important; 
}
.btn-outline-info {      
    border-color: var(--synth-light-blue) !important; 
    color: var(--synth-light-blue) !important; 
}
.btn-outline-info:hover {
    background-color: var(--synth-light-blue) !important; 
    color: white !important;
}
.btn-outline-info:a {
    color: var(--synth-light-blue) !important;
}

/* Light overrides*/
.text-light {
    color: var(--synth-light-gray) !important; 
}
.bg-light, .badge-light {
    background-color: var(--synth-light-gray) !important;
}
.border-light {
    border-color: var(--synth-light-gray) !important;     
}
.btn-light {    
    background-color: var(--synth-light-gray) !important; 
    border-color: var(--synth-light-gray) !important; 
}
.btn-outline-light {      
    border-color: var(--synth-light-gray) !important; 
    color: var(--synth-light-gray) !important; 
}
.btn-outline-light:hover {
    background-color: var(--synth-light-gray) !important; 
    color: var(--synth-blue) !important;
}
.btn-outline-light:a {
    color: var(--synth-light-gray) !important;
}

/* dark overrides*/
.text-dark {
    color: var(--synth-dark-blue) !important; 
}
.bg-dark, .badge-dark {
    background-color: var(--synth-dark-blue) !important;
}
.border-dark {
    border-color: var(--synth-dark-blue) !important;     
}
.btn-dark {    
    background-color: var(--synth-dark-blue) !important; 
    border-color: var(--synth-dark-blue) !important; 
}
.btn-outline-dark {      
    border-color: var(--synth-dark-blue) !important; 
}
.btn-outline-dark:hover {
    background-color: var(--synth-dark-blue) !important; 
    color: white !important;
}

.alert-dark {
    border-color: var(--synth-dark-blue) !important; 
    background-color: rgba(0, 66, 80, 0.1) !important;  
    opacity: 0.9;
}

.alert-success {
    border-color: var(--synth-green) !important; 
    background-color: rgba(122, 184, 0, 0.2) !important;  
    opacity: 0.9;
}

.alert-info {
    border-color: var(--synth-light-blue) !important; 
    background-color: rgba(114, 199, 231, 0.2) !important; 
    opacity: 0.9;
}

.alert-primary {
    border-color: var(--synth-mid-blue) !important; 
    background-color: rgba(0, 117, 176, 0.15) !important; 
    opacity: 0.9;
}

.alert-warning {
    border-color: var(--synth-orange) !important; 
    background-color: rgba(245, 112, 0, 0.2) !important;
    opacity: 0.9;
}


/* dark green*/
.text-dar-green {
    color: var(--synth-dark-green) !important; 
}
.bg-dark-green, .badge-dark-green {
    background-color: var(--synth-dark-green) !important;
}
.border-dark-green {
    border-color: var(--synth-dark-green) !important;     
}




