body {
/*    padding-top: 70px; 
    padding-bottom: 19px; */
/*     background-color: var(--synth-broken-white); */
    background-color: #fdfdfd;
}

body .container, body .container-lg, body .container-xl, body .container-sm, body .container-md {
    margin-top: 65px;
/*    margin-bottom: 40px;*/
}

body > .container > .card, body > .container-lg > .card, body > .container-xl > .card, 
body > .container-sm > .card, body > .container-md > .card, 
body > .container > form, body > .container-lg > form, body > .container-xl > form 
body > .container-sm > form, body > .container-md > form {
    margin-bottom: 40px;
}

.modal-open > .container {
    filter: blur(2px);
}


#smallModal > .modal-dialog {
    max-width: 350px;
}

/*.bg-img {
  background-image: url(/img/synthetron-backdrop-blurred.png);
  background-repeat: no-repeat;
   position: absolute; 
  background-attachment:fixed;
   filter: blur(3px) opacity(0.6); 
  height:100%;
  width:100%;
  top:0;
  z-index: -1;

}*/

.error {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
    min-height: 80px;
}

ul.alert-secondary {    
    list-style: none;
} 



ul.alert-secondary li::before {
  content: "\220E";
  /* content: "\f00d"; */
  size: 14px;
  color: var(--synth-yellow);
  display: inline-block;
  width: 1em; 
  margin-left: -1em; 

} 

.alert-success > .close {
  line-height: 0.5;
  color: var(--synth-green); 
}

.color-thumbnail {
    font-size: 40px;
    height: 40px;
    width:40px;       
}

.img-thumbnail.placeholder {
    width: 50px;
    height: 50px;    
    line-height: 62px;
    text-align: right;
    max-width: inherit;
    display: inline-block;
}

.d-table-cell .img-thumbnail {
    max-width: 90%;
}

.img-thumbnail.placeholder > i {
    color: lightgray !important;
}
.msg {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
} 

#forgot-box {
    margin: 100px auto;	
}

.card, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .navbar {
    background-color: #eef0f3;
    background-color: var(--synth-light-gray) !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border-color: var(--synth-mid-blue) var(--synth-mid-blue) transparent;
}

.nav-item.active > .nav-link {
    color: var(--synth-mid-blue);
}

/*.navbar-light .navbar-nav .active>.nav-link {
    color: var(--synth-mid-blue);
}*/

.navbar-toggler {
  color: rgba(0,0,0,.5);
  border-color: rgba(0,0,0,.1);
}

/*.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-bottom-color: #eef0f3; 
}*/


/*.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: var(--synth-mid-blue) var(--synth-mid-blue) transparent;
}*/

.nav-tabs {
    border-bottom: 1px solid var(--synth-mid-blue);
}


.navbar.fixed-top  {
    background-color: rgba(223, 223, 223, 0.95);
    border-bottom: 1px solid var(--synth-gray) !important;
}

.navbar-nav .nav-link {
  color: rgba(0,0,0,.5);
}

.navbar.fixed-bottom {
    background-color: rgba(197, 197, 197, 0.9);
    line-height: normal;
    
}
.card-header .nav-tabs {
     border-bottom: #eef0f3; 
     border-bottom: 1px solid rgba(0,0,0,.125); 
}


#discussionSearchBox {
 background-color: transparent;  
 color:white; 
}

#discussionSearchBox::-webkit-input-placeholder,#discussionSearchBox::-webkit-placeholder   {  
 color:white;   
}

#discussionSearchBox::-ms-input-placeholder {  
 color:white;   
}

#discussionSearchBox::placeholder {  
 color:white;   
}

.pg-selected {
    font-size: 15px; 
    background:#bce8f1; 
    padding: 2px 4px 2px 4px;
}
.pg-normal {
    cursor: pointer;
}

.shortcut {
    margin-bottom:2px; 
    width:90px;
    overflow-x: hidden;
    text-align: left;
}

.table-striped {
    min-height: 150px;
}

.page-header {
    margin-top: 10px;
}

.lh-2{ /*vertically center text in headers*/
    line-height: 2;
}

.link-fw {
    width: 180px;
    text-align: left;
    height: 35px;
    overflow: hidden;
}
.dropshadow {
    -webkit-box-shadow: 1px 1px 15px 0px rgba(110,110,110,0.31);
    -moz-box-shadow: 1px 1px 15px 0px rgba(110,110,110,0.31);
    box-shadow: 1px 1px 15px 0px rgba(110,110,110,0.31);
}

td, .div-overflow {    
    overflow: hidden; 
/*    white-space: nowrap; */
    text-overflow: ellipsis;
}

td {    
    /*max-width: 0;*/
    min-width: 55px;
}

.table td, .table th {
    word-wrap: break-word;
    vertical-align: middle;
}

table.rounded-lg > tbody > tr:first-child {
    min-height: 90px;
}


table.rounded-lg > thead > tr:first-child > th, table.rounded-lg > thead > tr:first-child > td {
    border-top: none;
}

table.border tbody {
    background-color: white;
}


.table#sortable td,  .table#sortable th {
    padding: 5px;
}

.table-hover > tbody > tr.no-hover:hover > td,
.table-hover > tbody > tr.no-hover:hover > th {
    background-color: #eef0f3; /* TODO: improve this, should not have to specify color, just transparent or initial */
}

.clickable,.clickable-group, .followLink {
    cursor: pointer;
}

.navbar-nav>li {
    margin-right: 5px;
}

.navbar-nav>li.dropdown>a {

    padding-left: 5px;
}

.navbar-nav > .dropdown-toggle-split {

    padding-top: 9px !important;
    padding-left: -5px;
}

.name {
    text-transform: capitalize;
}

nav .navbar-brand {
  width: 90px;
  height: 46px;
  background-image: url(https://assets.synthetron.com/landing/img/logos/synthetron.png);
  background-repeat: no-repeat;
  background-size: contain;
}

div .logo-tile {
    width: 65px;
    height: 59px;
    float:left;
}

.navbar>.container-fluid .navbar-brand {
    margin-left: 0;
    margin-right: 15px;
}

.navbar {
    padding: 0 0;
}


/*
nav > .pagination { alternatively we can use the classes d-flex (nav) and flex-wrap (ul) in the html 
    max-width: 60vw !important;
}
*/
nav > .pagination {
    overflow-x: auto;
    max-width: 50vw;
}

label.alert {
    padding: .50rem 1.25rem;
    margin-bottom: 0.50rem;
}

.alert {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

h2 i {
    color: #134172;
}

.table>thead>tr>th {
    border-bottom: 0;
}

th[sort-col] {
    cursor: pointer;
}   

th[sort-col]:hover {
    background-color: #F5F5F5;
} 

.table-hover tr:hover > th .fa-tachometer {
    color: var(--synth-green);  
} 

.table-sm td,.table-sm th  {

    padding: 3px 0 3px 10px;
}
    
.well div[class^="col-"]  {
    padding-left: 5px;    
}

.bootstrap-tagsinput {
    width: 100%;
    padding: 4px 6px 6px !important;
}

.navbar-nav select.input-sm {
    font-weight: bold;
}

.tooltip > .tooltip-inner {
    opacity: 0.8;
}

 .tooltip.show li {
   text-align:left;
 }


.sublist, .answerSummary {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
}

.answerSummary {
    max-width: 100%;
}

#sortable tr { margin: 0 3px 3px 3px; padding: 0.4em; padding-left: 1.5em; height: 18px; }
.move{ cursor: move } 
.grab { cursor: -webkit-grabbing !important }

.center {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}


.no-padding {
        padding: 0 !important;
}

.image-panel {

    width: 955px;
    min-height: 540px;
    }
    
#adminPanel {
/*    position: absolute;
    z-index: 1;*/

}    

.page-list  {
    min-height: 400px;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    
}

.form-list, .card-body.mh  {
    min-height: 180px;
    overflow-y: auto; 
    max-height: 300px
}

.card-body {
   overflow-y: auto; 
}

.mh-sm {
    max-height: 200px;
}

.mh  {
    max-height: 300px;
    min-height: 300px;    
    }
    
.mh-lg  {
    max-height: 500px;
    min-height: 370px;
    }

.card-header > h6 {
    line-height: 29px;
}

.card-header .close {
    text-shadow: 0 0 0 #fff;
}

#adminPanel .card-footer small {
    line-height: 3;
}

optgroup[label] {
    text-transform: capitalize;
}
.form-control-sm#setCurrentAccountId option[selected="selected"] {
    color: var(--synth-green) !important;
    font-weight: bold;   
}

input[type=color].form-control {
    padding: 3px 4px 3px 3px;
    
}
/* for fontawesome hex icons, format: &#x[f2bd] */
select[multiple], optgroup[label] {
  font-family: FontAwesome, -apple-system, BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif;
}

select[multiple], .custom-select[size]:not([size="1"]) {
    overflow-y: auto;
}

.custom-select:disabled option:first-child {
    color: var(--synth-light-gray);

}

.form-control-sm#setCurrentAccountId  {
    width: 100px;
}

.form-control-sm#setCurrentTimeRange  {
    max-width: 10vw;
}

.form-control-sm#setCurrentAccountId  {
    font-weight: bold;    
    text-transform: capitalize;
}

.card .list-group-item {
    background-color: transparent;
}

.card a {
    cursor: pointer;
}

.timing {
    text-transform: capitalize;
}

.video-link i {
    vertical-align: middle;
}

.card-header.bg-primary .nav-link.active {
    color: var(--synth-blue);
    
}

.card-header.bg-primary .nav-link {
    color: var(--synth-blue) !important;    
}

.page-item.active .page-link {
    color: white !important;
}

.page-item.active .page-link {

    background-color: var(--synth-mid-blue);
    border-color: var(--synth-mid-blue);
}

.card-deck .card-header {
    min-height: 53px;    
}

.card-deck .card-header .btn-sm {
    font-size: 76%;    
}

.card-footer .btn-primary, .card-footer #loading  {
    min-width: 120px;
}

.card-header h5 {
    line-height: 1.5;
}

#hideBtn {
    z-index: 1;    
}

.panel-synthetron > .card-body > .table {
    margin-bottom: 0;
}

input[type=checkbox], input[type=radio] {
     height: 17px;
     width: 17px;
     vertical-align: bottom;
}

li.no-style {
    list-style-type: none;
}

ul.circle {
    list-style-type: circle;
}

li.disc {
    list-style-type: disc;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

a > .card-footer:hover, div#close.modal-footer:hover  {
    background-color: rgba(0,0,0,0.1);
    cursor: pointer;
}

.explodable, .explodable-sm  {
        display: none !important;
}


.dark-mode-enabled::before {
  content: "disabled";
  line-height: 2.2;
 
}

a, .btn-link {
    color: var(--synth-mid-blue);
}

 /*tab-footer > #lpnav-**/

.card {
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.15);
}
    
@media (max-width: 1200px) {  
    .collapsable-lg {
        display: none !important;
    }
    
    .explodable, .explodable-sm {
        display: none !important;
    }
   
    .card-deck .card {
        margin-right: 10px;
        margin-left: 10px;
    }
    
}
@media (max-width: 992px) {  
    .collapsable, .explodable-sm {
        display: none !important;
    }
    
    .explodable {
        display:block !important;
    }
    
/*    .table td, .table th {
        padding: 4px 6px !important;
    }*/
    
    .card-deck .card {
        margin-right: 5px;
        margin-left: 5px;
    }   
    
    .card {
        background-color: var(--synth-light-gray) !important;
    }
    
    .card.page-list  {
        box-shadow: inherit !important;
    }

}
@media (max-width: 768px) {  
    
/*    .table td, .table th {
        padding: 3px 4px !important;
    }*/
    
    .collapsable-sm {
        display: none !important;
    }
    
    .explodable-sm  {
        display:block !important;
    }
    
    .card-deck .card {
        margin-right: 2px;
        margin-left: 2px;
    }   
    
    nav > .pagination {
        max-width: 65vw !important;
    }

    .navbar-expand-md>.container, .navbar-expand-md>.container-fluid, .navbar-expand-md>.container-lg, .navbar-expand-md>.container-md, .navbar-expand-md>.container-sm, .navbar-expand-md>.container-xl {
        padding-right: 15px;
        padding-left: 15px;
    }

}

@media (min-width: 1200px) {
    .container {
        max-width: 1375px;
    }

}


